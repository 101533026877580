<template>
  <div>
    <div class="row justify-content-md-center" v-cloak v-if="!state.loaded">
      <div class="col col-lg-2"></div>
      <div class="col-md-auto mt-20">
        <rotate-square5></rotate-square5>
      </div>
      <div class="col col-lg-2"></div>
    </div>
    <div class="card" v-else>
      <div class="card-body">
        <form action="#" @submit.prevent="submit">
          <div class="form-body">
            <h3 class="card-title">Indeks</h3>
            <hr />

            <div class="row p-t-20">
              <div class="col-md-4">
                <label :class="['control-label']">
                  Kode
                  <small style="color: red">*</small>
                </label>
                <div>
                  <Input v-model="payload.kode" name="kode" type="text" />
                </div>
              </div>
            </div>

            <Input
              v-model="payload.unit_id"
              name="unit_id"
              disabled
              required
              type="hidden"
            />

            <!-- {{ this.$store.state.profile.user.unit_id }} -->

            <div class="row">
              <div class="col-md-12 m-t-10 m-b-10">
                <label>
                  Indeks
                  <small style="color: red">*</small>
                </label>
                <div>
                  <Input v-model="payload.name" name="name" type="text" />
                </div>
              </div>
            </div>

            <hr class="m-t-40" />
          </div>
          <div class="form-actions">
            <button type="submit" class="btn btn-success" title="Simpan">
              <i class="fa fa-check"></i> Save
            </button>
            &nbsp;
            <button
              type="button"
              @click="goBack"
              class="btn btn-inverse"
              title="Cancel"
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { RotateSquare5 } from "vue-loading-spinner";
import Input from "@/components/input/input";
import Multiselect from "vue-multiselect";
import Upload from "@/components/input/upload.vue";
import axios from "axios";
import store from "@/store";

export default {
  components: {
    Input,
    RotateSquare5,
    Upload,
    Multiselect,
  },
  computed: {
    editMode() {
      return !!this.$route.params.id;
    },
    state() {
      return this.$store.state.kodeSurat;
    },
    isMobile() {
      return this.$store.state.isMobile;
    },
  },
  data() {
    return {
      options: [
        "Select option",
        "Disable me!",
        "Reset me!",
        "Admin Dinas Komunikasi dan Informatika ( Kepala Seksi Dinas Komunikasi dan Informatika )",
        "label",
        "searchable",
      ],
      payload: {
        kode: "",
        name: "",
        unit_id: "",
      },
      isDisabled: false,
    };
  },
  async mounted() {
    this.initialize();
    this.getAllReference();
  },

  methods: {
    async initialize() {
      try {
        if (this.editMode) {
          axios.get(`/kodeSurat/${this.$route.params.id}`).then((response) => {
            const state = {
              loaded: true,
            };
            this.$store.commit("kodeSurat/STATE", state);
            this.edit(response.data.data);
          });
        } else {
          this.$store.dispatch("kodeSurat/onAdd");
          // axios.get(`/document_in/new`).then(response => {
          //   // this.payload.agenda = response.data.data.agenda_number;
          //   // this.payload.tanggal = response.data.data.document_date;
          // });
        }
      } catch (e) {
        this.handleApiError(e);
        this.error = e.message;
      }
    },
    edit(data) {
      this.payload = {
        kode: data.kode,
        name: data.nama_klasifikasi,
        unit_id: data.unit_id,
      };
    },
    goBack() {
      this.$store.dispatch("kodeSurat/onCancel");
    },
    getAllReference() {
      this.payload.unit_id = this.$store.state.profile.user.unit_id;
    },
    submit() {
      const payload = {
        kode: this.payload.kode,
        name: this.payload.name,
        unit_id: this.payload.unit_id,
      };
      const payload2 = {
        id: this.$route.params.id,
        kode: this.payload.kode,
        name: this.payload.name,
        unit_id: this.payload.unit_id,
      };
      const data = JSON.stringify(payload);
      this.$validator.validateAll().then((success) => {
        if (this.editMode) {
          this.$store.dispatch("kodeSurat/submitEdit", payload2);
        } else {
          this.$store.dispatch("kodeSurat/submitAdd", payload);
        }
      });
    },
    clearAll() {
      this.payload.kepada = [];
    },
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
.multiselect__tags {
  border: 1px solid #ced4da !important;
}
.invalid .multiselect__tags {
  border-color: #f04124 !important;
  padding: 5px 40px 0 8px !important;
}
.invalid .typo__label {
  color: #f04124;
  font-size: 0.8125rem;
}
.multiselect {
  color: #54667a !important;
}
.multiselect__select {
  height: 41px !important;
}
</style>

